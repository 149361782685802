<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Accounting
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="14"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item active> ACCOUNTING </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <div
        class="col-xl-2 col-md-3 col-sm-6"
        v-for="data in modules"
        :key="data.route"
      >
        <b-col>
          <statistic-card-vertical
            :icon="data.icon"
            :statistic="data.title"
            color="success"
            :route="data.route"
          />
        </b-col>
      </div>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    // kFormatter,
    StatisticCardVertical,

    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "ColumnsIcon",
          title: "Land Purchase",
          route: "/accounting/landpurchase",
        },
        {
          icon: "PhoneIcon",
          title: "Voucher",
          route: "/accounting/voucher",
        },
        {
          icon: "EditIcon",
          title: "Receipt",
          route: "/accounting/receipt",
        },
        {
          icon: "DollarSignIcon",
          title: "Payment",
          route: "/accounting/payment",
        },
        {
          icon: "StarIcon",
          title: "Contra",
          route: "/accounting/contra",
        },
        {
          icon: "StarIcon",
          title: "Cheque Return",
          route: "/accounting/chequereturn",
        },
        {
          icon: "StarIcon",
          title: "Other Purchase",
          route: "/accounting/otherpurchase",
        },
      ],
      sidebarData: (this.sidebarData = JSON.parse(
        localStorage.getItem("sidebarData")
      )),

      userData: localStorage.getItem("userData"),
      userRole: "",
      modules: "",
    };
  },
  created() {
    // data
    // this.$http.get('/analytics/data').then((response) => {
    //   this.data = response.data
    // })
  },
  mounted() {
    this.userRole = JSON.parse(this.userData).role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.setRoute();
  },
  methods: {
    // kFormatter
    setRoute() {
      this.sidebarData.map((item) => {
        if (item.title == "Accounting") {
          this.modules = item.children;
        }
      });
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.route = inner.route;
          }
        });
      });
    },
  },
};
</script>
